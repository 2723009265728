.app {
    background-color: black;
    height: 98vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1vh;
    background-color: black;
}
  
.box {
    display: flex; /* Add this to align loader and input box */
    justify-content: flex-end; /* Add this to place loader at the right end */
    align-items: center;
    z-index: 10;
    width: 70vw;
    /* background-color: yellow; */
}
  
.input-box {
    color: white;
    border: none;
    outline: none;
    width: 59.6vw;
    font-family: 'Nunito', sans-serif;
    padding: 30px 20px 30px 50px;
    font-size: 20px;
    margin-right: 30px;
    background-color: #181818;
    border-radius: 5px;
    /* background-color: pink; */
}
  
.box.moved {
    animation: moveBox 3s forwards;
}
  
.input-box.moved {
    animation: inputMoveBox 3s forwards;
}
  
@keyframes moveBox {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(42.5vh);
      width: 85vw;
      justify-content: flex-end;
      margin-right:0;
      /* padding-left: 17vw; */
      
    }
}
  
@keyframes inputMoveBox {
    0% {
    }
    100% {
      /* width: 33vw; */
      padding: 15px 20px 15px 30px;
      font-size: 16px;
      color:#aaa;
    }
}
  

.iframe {
    position: fixed;
    top: 70px;
    height: 75vh;
    right: 11.5vw;
    outline: none;
    border:none;
    background-color: black;
    /* background-color: rgba(0, 0, 0, 0.1); */
}

.chat {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* background-color: tomato; */
}

.chat-container {
    position: fixed;
    bottom: 70px;
    left: 40%;
    transform: translateX(-50%);
    width: 30%;
    height: 75%;
    flex: 1;
    overflow-y: auto;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: nunito;
    /* border-color: yellow; */
    /* background-color: tomato; */
    color: white
}


.loader-container {
    position: fixed;
    display: flex;
    top: 70px;
    height: 75vh;
    width: 300px;
    right: 11.5vw;
    justify-content: center;
    align-items: center;
    background-color: black;
    z-index: 10;
  }

.loader-container.dissolve {
    animation: dissolve 2s forwards;
}

  
 /* Add loader styles */
.loader {
    border-radius: 50%;
    border-left: 3px solid grey;
    border-bottom: 3px solid grey;;
    width: 30px;
    height: 30px;
    animation: spin 2s linear infinite;
  }

.invisible {
    border-radius: 50%;
    border-left: 3px solid black;
    border-bottom: 3px solid black;;
    width: 30px;
    height: 30px;
}

.loader.dissolve {
    animation: spin-dissolve 2s linear infinite;
}
  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

@keyframes spin-dissolve {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
      border: 0 solid rgba(0, 0, 0, 0);
    }
}

  
@keyframes dissolve {
    0% {
    }
    100% {
     background-color: rgba(0, 0, 0, 0);
     
    }
}
  
  
  
  
  
  